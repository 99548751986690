<!-- src/views/AppChecker.vue -->
<template>
  <div class="container mt-5">
    <div class="col-md-12">
      
      <div class="loader-container" v-if="loading">
        <vue-loaders-pacman color="white" class="loader" scale="1"></vue-loaders-pacman>
      </div>
      <form id="myForm" onsubmit="return false">
        <div class="form-group">
          <label for="exampleTextarea">Example: XXXXXXXXXXXXXXXX|XX|XX|XXX</label>
          <textarea v-model="textareaValue" class="form-control" id="exampleTextarea" @change="handleChange" rows="10"

                    @input="check"></textarea>
          <small class="form-text text-muted text-white-50">Max 10 Rows.</small>
          <div id="preview" class="mt-3"></div>
          <div id="hataMesaji" class="mt-3 text-danger"></div>
        </div>


        <div class="containerss mt-0">
          <div :class="{ 'switch-container': true, 'switch-disabled': !autoParserSwitch }">
            <label for="autoParserSwitch" @click="toggleSwitch">Auto Parser:</label>
            <input type="checkbox" id="autoParserSwitch" v-model="autoParserSwitch" style="display: none">
            <div class="switch"
                 :style="this.autoParserSwitch === false ? 'background-color: #12D4B6;' : 'background-color: #8B8B8B;'"
                 @click="toggleSwitch"></div>
          </div>
        </div>

        <button class="btn btn-primary" style="width: 100%" id="checkButton" @click="newCardAdd">Check</button>

      </form>

      <div class="mt-5">
        <h5>Checked</h5>
        <table class="table table-dark table-bordered">
          <thead>
          <tr>
            <th scope="col">Card</th>
            <th scope="col">Bank</th>
            <th scope="col">Type</th>
            <th scope="col">Statu</th>
          </tr>
          </thead>
          <tbody>
          <div v-if="!loading">
          </div>
          </tbody>
        </table>
      </div>

    </div>


  </div>
</template>

<script>
import axios from 'axios';
import creditCardRegex from 'credit-card-regex';
import {AuthMixin} from '@/mixins/AuthMixin';

export default {
  mixins: [AuthMixin],
  inject: ['apiUrl'],
  name: 'AppChecker',
  data() {
    return {
      textareaValue: '',
      message: '!',
      loading: false,
      autoParserSwitch: true,
    };
  },
  methods: {
    toggleSwitch() {
      this.autoParserSwitch = !this.autoParserSwitch;
      this.toggleAutoParser();
      if (this.autoParserSwitch === false) {
        this.$swal({
          icon: 'info',
          title: 'Info!',
          text: "",
          customClass: {
            popup: 'dark-modeMer',
          },
          html: '<p>The auto-parser feature helps you find the correct formats among complex inputs provided in bulk and creates a clean output. When you currently deactivate this feature, you should submit the data as shown in the example section.</p><h5>Example: 4242424242424242|12|30|442 </h5>',
          iconColor: '#00b894',
          confirmButtonColor: '#00b894',
        })
      }

    },
    toggleAutoParser() {
      const savedValue = localStorage.getItem('autoParserSwitch');

      if (savedValue !== null && savedValue !== this.autoParserSwitch.toString()) {
        localStorage.setItem('autoParserSwitch', this.autoParserSwitch);
      } else {
        localStorage.setItem('autoParserSwitch', this.autoParserSwitch);
      }
    },
    checkLocalStorage() {
      const savedValue = localStorage.getItem('autoParserSwitch');
      if (savedValue !== null) {
        if (this.autoParserSwitch.toString() !== savedValue) {
          this.autoParserSwitch = savedValue === 'true';
        }
        console.log(savedValue);
      }
    },
    handleChange() {
      this.replace();
    },
    replace() {
      if (this.autoParserSwitch === true) {
        let textarea = document.getElementById("exampleTextarea");

        if (textarea) {
          let lines = textarea.value.split("\n");
          let cardNumber, month, year, cvv;
          let validateData = [];
          let errorData = [];
          document.getElementById("exampleTextarea").value = '';
          for (let i = 0; i < lines.length; i++) {
            let line = lines[i]
            let lineError = line;


            line = line.replace(/\b(\d{4})\s(\d{4})\s(\d{4})\s(\d{4})\b/g, '::$1$2$3$4::');
            line = line.replace(/[a-zA-ZçÇğĞıİöÖşŞüÜ]/g, '');

            let ipRegex = /\b(?:\d{1,3}\.){3}\d{1,3}\b/g;
            line = line.replace(ipRegex, '');


            line = line.replace(/\//g, '|');
            const regex = creditCardRegex();

            // Kredi kartı numarasını bul
            let cardNumberMatch = line.match(regex);
            if (cardNumberMatch) {
              cardNumber = cardNumberMatch[0];
              if (!this.luhnCheck(cardNumber)) {
                errorData.push([line]);
              } else {

                line = line.replace(cardNumber, '');
                line = line.replace(/\|(\d{2,12})\|(\d{2})/g, "|$1|$2");
                line = line.replace(/\|(\d{2})(\d{2})/, "|$2|");
                line = line.replace(/\|\|/g, "|");

                let dateMatch = line.match(/(\d{1,2}[/|.]\d{1,2})/);
                if (dateMatch) {
                  let date = dateMatch[1].replace(/[/|.]/, "|");
                  let [rawMonth, rawYear] = date.split("|");
                  month = parseInt(rawMonth);
                  year = parseInt(rawYear);

                  let s = '|';
                  line = line.replace(month, '');
                  line = line.replace(year, '');
                  line = line.replace(s, '');
                  line = line.replace()
                  let part = /(\d{3})/g;
                  var result = part.exec(line);

                  if (result) {
                    cvv = result[0];
                  }
                  if (month < 10) {
                    month = "0" + month;
                  }

                  if (month >= 1 && month <= 12 && (year >= 23 && year <= 35)) {
                    var firstThreeChars = cvv.slice(0, 3);
                    var numericValue = parseInt(firstThreeChars, 10);


                    if (!isNaN(numericValue)) {
                      line = cardNumber + "|" + month + "|" + year + "|" + cvv;
                      validateData.push([line]);
                    } else {
                      if (line)
                        errorData.push([lineError])
                    }
                  } else {
                    errorData.push([lineError])
                  }
                }
              }
            } else {
              errorData.push([lineError])
            }
          }
          this.textareaValue = validateData.join('\n');
        }

      }
    },
    checkFormat(data) {
      const parts = data.split('|');
      if (parts[0].length === 16 &&
          !isNaN(parts[1]) &&
          parseInt(parts[1]) >= 1 && parseInt(parts[1]) <= 12 &&
          !isNaN(parts[2]) &&
          parseInt(parts[2]) >= 23 && parseInt(parts[2]) <= 35 &&
          !isNaN(parts[3]) &&
          parts[3].length === 3) {

        const firstPart = parts[0];
        const secondPart = parseInt(parts[1]);
        const thirdPart = parseInt(parts[2]);
        const fourthPart = parts[3];

        return {
          firstPart,
          secondPart,
          thirdPart,
          fourthPart
        };
      } else {
        return null;
      }
    },
    luhnCheck(cardNumber) {
      if (typeof cardNumber !== 'string') {
        console.error('cardNumber bir string değil');
        return false;
      }

      let sum = 0;
      let numDigits = cardNumber.length;
      let parity = numDigits % 2;

      for (let i = 0; i < numDigits; i++) {
        let digit = parseInt(cardNumber[i]);

        if (i % 2 === parity) {
          digit *= 2;
          if (digit > 9) {
            digit -= 9;
          }
        }

        sum += digit;
      }

      return sum % 10 === 0;
    },
    check() {
      var textarea = document.getElementById("exampleTextarea");
      var hataMesaji = document.getElementById("hataMesaji");
      var checkButton = document.getElementById("checkButton");
      var preview = document.getElementById("preview");

      if (!textarea || !preview || !hataMesaji || !checkButton) {
        console.error("");
        return;
      }

      var satirlar = textarea.value.split('\n');
      var errorRows = [];

      for (var i = 0; i < satirlar.length; i++) {
        var satir = satirlar[i].trim();
        var regex = /^\d{14,16}\|\d{2}\|\d{2}\|\d{3}$/;
        if (!regex.test(satir)) {
          errorRows.push(i + 1);
          satir = '<span style="color: red;">' + satir + '</span>';
          satirlar[i] = satir;
        }
      }

      if (errorRows.length > 0) {
        hataMesaji.innerHTML = 'Error Rows: ' + errorRows.join(', ');
        checkButton.disabled = false;
        preview.innerHTML = satirlar.filter((_, index) => errorRows.includes(index + 1)).join('<br>');
      } else {
        hataMesaji.innerHTML = '';
        checkButton.disabled = false;
        preview.innerHTML = '';
      }
    },
    async newCardAdd() {
      await this.replace()
      try {
        this.loading = true;
        const response = await axios.post(this.apiUrl + '/api/checker/add', {
          data: this.textareaValue.split('\n')
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (response.data.status) {
          this.$swal.fire({
            icon: 'success',
            title: response.data.message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer);
              toast.addEventListener('mouseleave', this.$swal.resumeTimer);
            },
          });
        }
        setTimeout(() => {
          this.loading = false;
        }, 1500);
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    this.checkLocalStorage();
  }
};
</script>

<style scoped>
.loader-container {
  position: fixed;
  top: 35%;
  left: 45%;
  transform: translate(-40%, -45%);
  backdrop-filter: blur(5px);
  background: rgba(33, 37, 41, 0.8);
  border-radius: 10px;
  padding: 100px;
}

.loader {
  color: #fff;
  position: fixed;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.containerss {
  width: 95%;
  margin: 20px auto;
  position: relative;
}

.switch-container {
  position: absolute;
  top: calc(50% - 1.3rem);
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.switch {
  width: 50px;
  height: 25px;
  border-radius: 12.5px;
  position: relative;
  margin-left: 10px;
}

.switch::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
}

.switch-disabled {
  opacity: 0.5;

}

input:checked + .switch::before {
  transform: translate(25px, -50%);
}

label {
  top: calc(50% - 0.1rem);
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
}

</style>
  