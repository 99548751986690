
<template>

  <div style="height: 100px;"></div>
  <footer class="footer mt-auto py-3" style="background-color: #212529 !important; z-index: 1000">
    <div class="container text-center">
      <p class="mb-0 text-white">© 2023 Masked Community</p>
    </div>
  </footer>


</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
body {
  margin-bottom: 100px !important;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #212529;
  color: #ffffff;
  padding: 15px 0;
}

</style>
  