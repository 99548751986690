<template>
  <div
      class="container">
    <div
        class="loader-container"
        v-if="loading">
      <vue-loaders-pacman
          color="white"
          class="loader"
          scale="1"></vue-loaders-pacman>
    </div>
    <div
        class="row mt-3">
      <div
          class="col-md-6 mt-2">
        <div
            class="card">
          <div
              class="card-header"
              style="color: #9B9D9E !important;">
            Crypto
            Payment
          </div>
          <div
              class="card-body text-white-50">
            <div
                class="form-group">
              <label
                  for="walletAddress">USDT
                TRC20
                Wallet:</label>
              <input
                  type="text"
                  class="form-control"
                  id="walletAddress"
                  :value="this.wallet"
                  placeholder="Wallet Adresi">
            </div>
            <button
                class="btn btn-primary mt-2"
                style="width: 100%"
                @click="copyAddress()">
              Wallet
              Copy
            </button>
            <div
                class="mt-3">
              <label>Information:</label>
              <p class="mt-3">
                This
                payment
                method
                accepts
                only
                the
                USDT
                TRC20
                cryptocurrency.</p>
              <p class="mt-0">
                Minimum
                Investment:
                $30</p>
            </div>
          </div>
        </div>
      </div>
      <div
          class="col-md-6 mt-2">
        <div
            class="card">
          <div
              class="card-header"
              style="color: #9B9D9E !important;">
            QR
            Code
          </div>
          <div
              class="card-body text-center">
            <img
                :src="'https://qrcode.tec-it.com/API/QRCode?data='+this.wallet"
                width="250"
                alt="QR Kod"
                id="qr-code">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
      class="container mt-5">
    <div
        class="table-header mb-4">
      Payment
      History
    </div>
    <div
        class="table-responsive">
      <table
          class="table table-dark">
        <thead
            class="thead-dark">
        <tr>
          <th scope="col">
            Wallet
          </th>
          <th scope="col">
            Amount
          </th>
          <th scope="col">
            Status
          </th>
          <th scope="col">
            Date
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in this.paymentHistory"
            :key="index">
          <td>
            {{
              item.wallet
            }}
          </td>
          <td>
            {{
              item.amount
            }}
          </td>
          <td>
            {{
              item.status
            }}
          </td>
          <td>
            {{
              item.created_at
            }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
import axios
  from 'axios';

export default {
  name: "AppAddmoney",
  data() {
    return {
      loading: false,
      wallet: '',
      paymentHistory: []
    };
  },
  inject: ['apiUrl'],
  methods: {
    async getWallet() {
      try {
        this.loading = true;

        const response = await axios.get(this.apiUrl + '/api/wallet/add', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Accept': 'application/json',
          },
        });


        this.wallet = response.data.wallet
        setTimeout(() => {

          this.loading = false;
        }, 1500);
      } catch (error) {

        console.error(error);
      }
    },
    async getHistory() {
      try {
        this.loading = true;

        const response = await axios.get(this.apiUrl + '/api/wallet/history', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Accept': 'application/json',
          },
        });

        this.paymentHistory = response.data.history
        setTimeout(() => {
          this.loading = false;
        }, 1500);
      } catch (error) {

        console.error(error);
      }
    },
    copyAddress() {
      let addressInput = document.getElementById("walletAddress");
      addressInput.select();
      document.execCommand("copy");
      alert("Wallet Copied: " + addressInput.value);
    }
  },
  async created() {
    await this.getWallet();
    await this.getHistory();
  },
  async mounted() {
  }
};
</script>


<style>
table {
  background-color: #333;
  color: #fff;
}

th, td {
  border: 1px solid #555;
}

.table-header {
  text-align: center;
  font-size: 24px;
  background-color: #343a40;
  color: #fff;
  padding: 5px;
}

.card {
  background-color: #212529 !important;
}

.card-header {
  background-color: #212529 !important;
  color: #fff !important;
}

#qr-code {
  max-width: 100% !important;
}

.loader-container {
  position: fixed;
  top: 35%;
  left: 45%;
  transform: translate(-40%, -45%);
  backdrop-filter: blur(5px);
  background: rgba(33, 37, 41, 0.8);
  border-radius: 10px;
  padding: 100px;
  z-index: 400;
}

.loader {
  color: #fff;
  position: fixed;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
}
</style>