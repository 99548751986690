<template>
  <div
      class="container  my-4">
    <div
        class="loader-container"
        v-if="loading">
      <vue-loaders-pacman
          color="white"
          class="loader"
          scale="1"></vue-loaders-pacman>
    </div>




  </div>
</template>

<script>
/*
import axios
  from 'axios';
*/


export default {
  inject: ['apiUrl'],
  data() {
    return {
      services: [
        // Example service data
      ]

    };
  },
  name: "AppCloaker",
  computed: {},
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    showNotification(icon, title, htmlContent, toast = false) {
      const swalOptions = {
        customClass: {
          container: 'dark-mode',
          background: 'dark-background',
        },
        icon,
        title,
        html: htmlContent, // HTML içeriği için doğru kullanım
        iconColor: '#ff7675',
        confirmButtonColor: '#d63031',
        cancelButtonColor: '#636e72',
      };

      if (toast) {
        this.$swal.fire({
          ...swalOptions,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer);
            toast.addEventListener('mouseleave', this.$swal.resumeTimer);
          },
        });
      } else {
        this.$swal.fire(swalOptions); // fire yerine direkt fire kullanımı
      }
    }
  },
  async created() {

  },
};

</script>

<style>
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  font-weight: bold;
}

.card-body {
  font-size: 1.1rem;
}

.menu-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-item {
  background-color: #282c30;
  color: #fff;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.menu-item strong {
  flex: 1;
  color: #6C6F8B;
  text-align: left;
}

.menu-item span {
  flex: 2;
  text-align: left;
}


.menu-item:last-child {
  border-bottom: none;
}

.menu-item:hover {
  background-color: #0A6EFD;
}

.table-dark {
  --bs-table-color: #DEE2E6;
  --bs-table-bg: #293a40;
}

.table-dark {
  border-collapse: collapse;
  width: 100%;
  border: 1.5px groove #DEE2E6;
  border-radius: 12px !important;
}

.table tbody tr:hover {
  background-color: #555 !important;
}


.table.table-dark tbody tr:hover {
  background-color: #555 !important;
}

.table.table-dark {
  width: 100%;
}

.table.table-dark tbody tr td {
  min-width: 30px !important;
}

.table.table-dark tbody tr td {
  padding: 4px;
}

.table.table-dark tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.table.table-dark th {
  border-right: 1px solid #dee2e6;
}


.table.table-dark td {
  border-right: 1px solid #dee2e6;
}


.table.table-dark td:first-child,
.table.table-dark th:first-child {
  border-left: none;
}

/* Son sütundaki hücre için sağ kenar çizgisini kaldırma (isteğe bağlı) */
.table.table-dark td:last-child,
.table.table-dark th:last-child {
  border-right: none;
}


/* Media query for small screens */
@media (max-width: 767px) {
  table {
    border-collapse: collapse !important;
  }

  th, td {
    background-color: #293a40 !important;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;

  }

  .hidden-column {
    display: none;
  }

  .mobileShow {
    display: table-cell;
  }

  .expandable-content {
    background-color: #293a40 !important;
    display: none !important;
  }

  .expandable-content td {
    border-top: 1px solid #ddd;
    display: table-cell !important;
  }

  .expandable-content th,
  .expandable-content td {
    background-color: #343A40;
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
    display: table-cell !important;
  }
}


.pagination-button {
  background-color: #343a40;
  color: #ffffff;
  border: 1px solid #606c76;
  cursor: pointer;
  padding: 8px 12px;
  margin: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}


.pagination-button:hover {
  background-color: #495057;
  color: #ffffff;
}


.pagination-button.active {
  background-color: #007bff;
  color: #ffffff;
}


.pagination-button.disabled {
  background-color: #6c757d;
  color: #ffffff;
  cursor: not-allowed;
}

.clear-button {
  background-color: #293a40;
  color: #ffffff;
  border: 1px solid #dee2e6;
  width: 100%;
  height: 35px;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  display: block;
}


.clear-button:hover {
  background-color: #1c2930;
}

.button-text {
  color: #DEE2E6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.loader-container {
  position: fixed;
  top: 35%;
  left: 45%;
  transform: translate(-40%, -45%);
  backdrop-filter: blur(5px);
  background: rgba(33, 37, 41, 0.8);
  border-radius: 10px;
  padding: 100px;
}

.loader {
  color: #fff;
  position: fixed;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
}

</style>
