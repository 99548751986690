import AuthService
    from '@/services/AuthService';

export const AuthMixin = {
    data() {
        return {
            loggedIn: false,
            user: null,
            username: '',
            balance: '',
            password: ''
        };
    },
    methods: {

        async login() {
            try {
                const {
                    username,
                    password
                } = this;
                const userData = await AuthService.loginForm(username, password);
                if (userData.error) {
                    console.log(userData.error)
                } else {
                    this.loggedIn = true;
                    this.username = userData.username;
                    this.balance = userData.balance;
                    window.location.href = '/';
                }

            } catch (error) {
                console.error('Login failed', error.message);
            }
        },
        async checkLoginStatus() {
            try {
                const userData = await AuthService.checkLoginStatus();
                if (userData.error) {
                    this.loggedIn = false;
                    this.logout();
                } else {
                    this.loggedIn = true;
                    this.username = userData.username;
                    this.balance = userData.balance;
                    return userData;
                }
            } catch (error) {
                console.error('Error checking login status',);
            }
        },
        logout() {
            try {
                AuthService.logout();
                this.loggedIn = false;
                this.balance = 0;
                this.username = '';
            } catch (error) {
                console.error('Logout failed', error);
            }
        },

    },
    async mounted() {
        const token = localStorage.getItem('authToken');

        if (token) {

            this.checkLoginStatus();
        }
    }

};