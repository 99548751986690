<template>
  <div
      class="container mt-4">
    <div
        class="loader-container"
        v-if="loading">
      <vue-loaders-pacman
          color="white"
          class="loader"
          scale="1"></vue-loaders-pacman>
    </div>
    <div
        class="row">

      <div
          class="col-md-3">

        <button
            v-if="showClearButton"
            @click="clearFilters"
            class="clear-button">
          <span
              class="button-text">Clear Filters</span>
        </button>


        <div
            class="mb-3">
          <label
              for="select1">BIN</label>
          <input
              type="text"
              class="form-control"
              placeholder="434512"
              v-model="binInput"
              @input="handleChange('binInput')">
        </div>

        <div
            class="mb-3">
          <label
              for="cc_typeInput">Type:
            {{
              cc_typeInput
            }}</label>
          <select
              class="form-select"
              @focus="getCardType"
              id="cc_typeInput"
              v-model="cc_typeInput"
              @input="handleChange('cc_typeInput')">
            <option
                value="">
              Select
              Type
            </option>
            <option
                v-for="option in cardType[0]"
                :key="option.cc_type"
                :value="option.cc_type">
              {{
                option.cc_type
              }}
            </option>

          </select>
        </div>

        <div
            class="mb-3">
          <label
              for="cc_countryInput">Country:
            {{
              cc_countryInput
            }}</label>
          <select
              @focus="getCardCountry"
              class="form-select"
              id="cc_countryInput"
              v-model="cc_countryInput"
              @input="handleChange('cc_countryInput')">
            <option
                value="">
              Select
              Country
            </option>
            <option
                v-for="country in cardCountry[0]"
                :key="country.cc_country"
                :value="country.cc_country">
              {{
                country.cc_country
              }}
            </option>

          </select>
        </div>
        <!-- Diğer 3 selectbox ve 4 input buraya gelecek -->
        <div
            class="mb-3">
          <label
              for="select1">Bank:
            {{
              cc_bankInput
            }}</label>
          <select
              @focus="getCardBank"
              class="form-select"
              id="cc_bankInput"
              v-model="cc_bankInput"
              @input="handleChange('cc_bankInput')">
            <option
                value="">
              Select
              Bank
            </option>
            <option
                v-for="bank in cardBank[0]"
                :key="bank.cc_bank"
                :value="bank.cc_bank">
              {{
                bank.cc_bank
              }}
            </option>
          </select>
        </div>

        <button
            class="btn btn-primary mt-2 mb-2"
            style="width: 100%"
            @click="fetchData(1)">
          Search
        </button>

      </div>

      <!-- Ürün Tablosu -->
      <div
          class="col-md-9">
        <div
            class="table-responsive">
          <label
              for="">LIST</label>
          <table
              class="table table-dark">
            <thead>
            <tr class="tablehead">
              <th scope="col"
                  class="mobileShow">
                CARD
              </th>
              <th scope="col"
                  class="tdtd hidden-column">
                Type
              </th>
              <th scope="col"
                  class="mobileShow">
                Bank
              </th>
              <th scope="col"
                  class=" tdtd hidden-column">
                Country
              </th>
              <th scope="col"
                  class="mobileShow">
                Refund
              </th>
              <th scope="col"
                  class="tdtd hidden-column">
                Price
              </th>
              <th scope="col"
                  class="mobileShow">
                Buy
              </th>
              <th scope="col"
                  class="mobileShow"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in this.dataCards"
                :key="index">
              <td :style="calculateCellStyle('▼************')"
                  class="mobileShow"
                  @click="toggleRow(index)"
                  :key="item.id">
                <span
                    style="color: #007BFF;">►</span>
                {{
                  item.bin + '|' + item.month + '/' + item.year
                }}
              </td>

              <td :style="calculateCellStyle(item.cc_type)"
                  class="tdtd hidden-column">
                {{
                  capitalizedText(item.cc_type)
                }}
              </td>

              <td :style="calculateCellStyle(item.cc_bank)"
                  class="mobileShow">
                {{
                  capitalizedText(item.cc_bank)
                }}
              </td>
              <td :style="calculateCellStyle(item.cc_country)"
                  style="text-align: center!important;"
                  class="tdtd hidden-column">
                {{
                  capitalizedText(item.cc_country)
                }}
              </td>

              <td :style="calculateCellStyle(item.refundable)"
                  class="mobileShow"
                  style="text-align: center!important;">
                <svg
                    v-if="item.refundable === 'true'"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 256 256"
                    width="18"
                    height="18">
                  <rect
                      width="256"
                      height="256"
                      fill="none"></rect>
                  <polyline
                      points="172 104 113.3 160 84 132"
                      fill="none"
                      stroke="#00C91BFF"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="18"></polyline>
                  <circle
                      style="color: #00c91b !important;"
                      cx="128"
                      cy="128"
                      r="96"
                      fill="none"
                      stroke="#00C91BFF"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="18"></circle>
                </svg>
                <svg
                    v-if="item.refundable == 'false'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    class="bi bi-x-circle "
                    viewBox="0 0 16 16"
                    style="color: #A20211FF !important;"
                >
                  <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </td>
              <td :style="calculateCellStyle('$'+item.price)"
                  class="tdtd hidden-column">
                ${{
                  item.price
                }}
              </td>
              <td :style="calculateCellStyle('Buy')"
                  class="mobileShow">
                <button
                    class="btn btn-primary btn-sm"
                    @click="buyCard(item.id)">
                  Buy
                </button>
              </td>
              <td :id="'row' + item.id"
                  :style="calculateCellStyle('')"
                  style="width: 25px; text-align: center;"
                  class="mobileShow">
                <label
                    v-if="item.cc_statu == 'live'"
                    style="color: #28A745">LIVE</label>
                <label
                    v-if="item.cc_statu == 'dec'"
                    style="color: #A20211FF">DEC</label>
              </td>

            </tr>

            </tbody>


          </table>
        </div>
        <button
            v-for="pageNumber in pageNumbers"
            :key="pageNumber"
            @click="changePage(pageNumber)"
            :class="{ 'active': pageNumber === currentPage, 'disabled': pageNumber === '...'}"
            class="pagination-button">
          {{
            pageNumber
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios
  from 'axios';


export default {
  inject: ['apiUrl'],
  data() {
    return {
      binInput: '',
      cc_typeInput: '',
      cc_countryInput: '',
      cc_bankInput: '',
      currentPage: 1,
      itemsPerPage: 50,
      totalItems: 0,
      showClearButton: false,
      dataCards: [],
      loading: false,
      cardType: [],
      cardCountry: [],
      cardBank: []
    };
  },
  name: "AppShop",
  computed: {
    totalPages() {
      console.log(Math.ceil(this.totalItems / this.itemsPerPage))
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    pageNumbers() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    updateItem(itemId, fieldName, value) {
      const index = this.dataCards.findIndex(item => item.id === itemId);


      if (index !== -1) {
        this.dataCards[index].bin = `${value}`;
      }
    },
    toggleRow() {
      const elements = document.querySelectorAll('.tdtd');


      elements.forEach(element => {
        const classList = element.classList;
        if (classList.contains('hidden-column')) {
          classList.remove('hidden-column');
        } else {
          classList.add('hidden-column');
        }
      })
    },
    capitalizedText(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    calculateCellStyle(value) {
      const fontSize = 18;

      const columnWidth = fontSize * value.length;

      return {
        fontSize: fontSize + 'px',
        width: columnWidth + 'px',
        textAlign: 'left',
      };
    },
    async handleChange(fieldName) {
      console.log(this[fieldName]);
      this.showClearButton = true;
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchData(pageNumber);
    },
    async clearFilters() {
      this.binInput = '';
      this.cc_typeInput = '';
      this.cc_countryInput = '';
      this.cc_bankInput = '';
      this.showClearButton = false;
      this.fetchData(1);
    },
    async fetchData(page) {
      this.loading = true
      const selectedPage = page ? page : this.currentPage;
      try {
        const formData = new FormData();
        formData.append('bin', this.binInput);
        formData.append('cc_type', this.cc_typeInput);
        formData.append('cc_country', this.cc_countryInput);
        formData.append('cc_bank', this.cc_bankInput);

        const response = await axios.post(
            `${this.apiUrl}/api/ccshop/allData?page=${selectedPage}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'multipart/form-data',
              },
            }
        );
        if (response.data.status === false) {
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: response.data.error,
            customClass: {
              container: 'dark-mode',
              background: 'dark-background',
            },
            iconColor: '#ff7675',
            confirmButtonColor: '#d63031',
            cancelButtonColor: '#636e72',
          })
          this.loading = false
        } else {
          this.totalItems = response.data.pagination.total;
          this.dataCards = response.data.data;
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        console.error('Error status login status');
      }
    },
    async buyCard(CardId) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append('id', CardId);

        const response = await axios.post(
            `${this.apiUrl}/api/ccshop/buy`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'multipart/form-data',
              },
            }
        );
        if (response.data.status === false) {
          this.loading = false;
          this.loading = false;
          this.$swal.fire({
            icon: 'error',
            title: response.data.message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {
              container: 'dark-mode',
              popup: 'dark-mode',
              header: 'dark-mode',
              title: 'dark-mode',
              closeButton: 'dark-mode',
              icon: 'dark-mode',
              image: 'dark-mode',
              content: 'dark-mode',
              input: 'dark-mode',
              actions: 'dark-mode',
              confirmButton: 'dark-mode',
              cancelButton: 'dark-mode',
              footer: 'dark-mode'
            },
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer);
              toast.addEventListener('mouseleave', this.$swal.resumeTimer);
            },
          });
        } else {
          this.loading = false;
          this.$swal.fire({
            icon: 'success',
            title: response.data.message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            customClass: {

              popup: 'dark-mode',
              header: 'dark-mode',
              title: 'dark-mode',
              closeButton: 'dark-mode',
              icon: 'dark-mode',
              image: 'dark-mode',
              content: 'dark-mode',
              input: 'dark-mode',
              actions: 'dark-mode',
              confirmButton: 'dark-mode',
              cancelButton: 'dark-mode',
              footer: 'dark-mode'
            },
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer);
              toast.addEventListener('mouseleave', this.$swal.resumeTimer);
            },
          });
          await this.updateItem(response.data.ccdata.id, 'bin', response.data.ccdata.cc_info);

        }
      } catch (error) {
        this.loading = false;
        console.error('Error status login status', error);
      }

    },
    async getCardType() {
      this.loading = true;
      let response = await axios.get(
          `${this.apiUrl}/api/ccshop/getCardType`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
      );
      response = response.data;
      console.log(response)
      if (response) {
        this.cardType = response
      }
      this.loading = false

    },
    async getCardCountry() {
      this.loading = true;
      let response = await axios.get(
          `${this.apiUrl}/api/ccshop/getCardCountry`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
      );
      response = response.data;
      console.log(response)
      if (response) {
        this.cardCountry = response
      }
      this.loading = false

    },
    async getCardBank() {
      this.loading = true;
      let response = await axios.get(
          `${this.apiUrl}/api/ccshop/getCardBank`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
      );
      response = response.data;
      console.log(response)
      if (response) {
        this.cardBank = response
      }
      this.loading = false

    }
  },
  async created() {
    await this.fetchData(this.currentPage)
  },
};

</script>

<style>


.table-dark {
  --bs-table-color: #DEE2E6;
  --bs-table-bg: #293a40;
}

.table-dark {
  border-collapse: collapse;
  width: 100%;
  border: 1.5px groove #DEE2E6;
  border-radius: 12px !important;
}

.table tbody tr:hover {
  background-color: #555 !important;
}


.table.table-dark tbody tr:hover {
  background-color: #555 !important;
}

.table.table-dark {
  width: 100%;
}

.table.table-dark tbody tr td {
  min-width: 30px !important;
}

.table.table-dark tbody tr td {
  padding: 4px;
}

.table.table-dark tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.table.table-dark th {
  border-right: 1px solid #dee2e6;
}


.table.table-dark td {
  border-right: 1px solid #dee2e6;
}


.table.table-dark td:first-child,
.table.table-dark th:first-child {
  border-left: none;
}

/* Son sütundaki hücre için sağ kenar çizgisini kaldırma (isteğe bağlı) */
.table.table-dark td:last-child,
.table.table-dark th:last-child {
  border-right: none;
}


/* Media query for small screens */
@media (max-width: 767px) {
  table {
    border-collapse: collapse !important;
  }

  th, td {
    background-color: #293a40 !important;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;

  }

  .hidden-column {
    display: none;
  }

  .mobileShow {
    display: table-cell;
  }

  .expandable-content {
    background-color: #293a40 !important;
    display: none !important;
  }

  .expandable-content td {
    border-top: 1px solid #ddd;
    display: table-cell !important;
  }

  .expandable-content th,
  .expandable-content td {
    background-color: #343A40;
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
    display: table-cell !important;
  }
}


.pagination-button {
  background-color: #343a40;
  color: #ffffff;
  border: 1px solid #606c76;
  cursor: pointer;
  padding: 8px 12px;
  margin: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}


.pagination-button:hover {
  background-color: #495057;
  color: #ffffff;
}


.pagination-button.active {
  background-color: #007bff;
  color: #ffffff;
}


.pagination-button.disabled {
  background-color: #6c757d;
  color: #ffffff;
  cursor: not-allowed;
}

.clear-button {
  background-color: #293a40;
  color: #ffffff;
  border: 1px solid #dee2e6;
  width: 100%;
  height: 35px;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  display: block;
}


.clear-button:hover {
  background-color: #1c2930;
}

.button-text {
  color: #DEE2E6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.loader-container {
  position: fixed;
  top: 35%;
  left: 45%;
  transform: translate(-40%, -45%);
  backdrop-filter: blur(5px);
  background: rgba(33, 37, 41, 0.8);
  border-radius: 10px;
  padding: 100px;
}

.loader {
  color: #fff;
  position: fixed;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
}

</style>
    