<template>
  <div
      class="container mt-4">
    <div
        class="loader-container"
        v-if="loading">
      <vue-loaders-pacman
          color="white"
          class="loader"
          scale="1"></vue-loaders-pacman>
    </div>
    <div
        class="row">
      <!-- Giriş Alanları -->


      <!-- Ürün Tablosu -->
      <div
          class="col-md-12">
        <div
            class="table-responsive">
          <label
              for="">Purchase
            History</label>
          <table
              class="table table-dark">
            <thead>
            <tr class="tablehead">
              <th scope="col">
                CARD
                INFO
              </th>
              <th scope="col">
                Type
              </th>
              <th scope="col">
                Bank
              </th>
              <th scope="col">
                Country
              </th>
              <th scope="col">
                Date
              </th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in this.dataCards"
                :key="index">
              <td :style="calculateCellStyle(item.cc_info)">
                {{
                  item.cc_info
                }}
              </td>

              <td :style="calculateCellStyle(item.cc_type)">
                {{
                  capitalizedText(item.cc_type)
                }}
              </td>

              <td :style="calculateCellStyle(item.cc_bank)">
                {{
                  capitalizedText(item.cc_bank)
                }}
              </td>
              <td :style="calculateCellStyle(item.cc_country)"
                  style="text-align: center!important;"
                  class="tdtd hidden-column">
                {{
                  capitalizedText(item.cc_country)
                }}
              </td>

              <td :style="calculateCellStyle( item.updated_at )">
                {{
                  this.formatDateString(item.updated_at)
                }}
              </td>
              <td :id="'row' + item.id"
                  :style="calculateCellStyle('')"
                  style="width: 25px; text-align: center;">
                <label
                    v-if="item.cc_statu === 'live'"
                    style="color: #28A745">LIVE</label>
                <label
                    v-if="item.cc_statu === 'dec'"
                    style="color: #A20211FF">DEC</label>
              </td>

            </tr>

            </tbody>


          </table>
        </div>
        <button
            v-for="pageNumber in pageNumbers"
            :key="pageNumber"
            @click="changePage(pageNumber)"
            :class="{ 'active': pageNumber === currentPage, 'disabled': pageNumber === '...'}"
            class="pagination-button">
          {{
            pageNumber
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios
  from 'axios';


export default {
  inject: ['apiUrl'],
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 50,
      totalItems: 0,
      dataCards: [],
      loading: false,
    };
  },
  name: "AppMycard",
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    pageNumbers() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    formatDateString(dateString) {
      var dateObject = new Date(dateString);

      var year = dateObject.getFullYear();
      var month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
      var day = ('0' + dateObject.getDate()).slice(-2);
      var hours = ('0' + dateObject.getHours()).slice(-2);
      var minutes = ('0' + dateObject.getMinutes()).slice(-2);
      var seconds = ('0' + dateObject.getSeconds()).slice(-2);

      var formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
      return formattedDate;
    },
    capitalizedText(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    calculateCellStyle(value) {
      const fontSize = 18;
      let columnWidth;
      if (value) {
        columnWidth = fontSize * value.length;
      } else {
        columnWidth = fontSize * 10;
      }


      return {
        fontSize: fontSize + 'px',
        width: columnWidth + 'px',
        textAlign: 'left',
      };
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchDataMyCard(pageNumber);
    },
    async fetchDataMyCard(page) {
      this.loading = true
      const selectedPage = page ? page : this.currentPage;
      try {

        const response = await axios.get(
            `${this.apiUrl}/api/ccshop/purchase-history/?page=${selectedPage}`,
            {
              method: 'get',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`
              },
            }
        );
        this.loading = false
        this.totalItems = response.data.pagination.total;
        this.dataCards = response.data.history;
      } catch (error) {
        this.loading = false
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Not Connect',
          customClass: {
            container: 'dark-mode',
            background: 'dark-background',
          },
          iconColor: '#ff7675',
          confirmButtonColor: '#d63031',
          cancelButtonColor: '#636e72',
        });
        console.error('Error status login status', error);
      }
    }
  },
  async created() {
    await this.fetchDataMyCard(1)
  },
};

</script>

<style
    scoped>


.table-dark {
  --bs-table-color: #DEE2E6;
  --bs-table-bg: #293a40;
}

.table-dark {
  border-collapse: collapse;
  width: 100%;
  border: 1.5px groove #DEE2E6;
  border-radius: 12px !important;
}

.table tbody tr:hover {
  background-color: #555 !important;
}


.table.table-dark tbody tr:hover {
  background-color: #555 !important;
}

.table.table-dark {
  width: 100%;
}

.table.table-dark tbody tr td {
  min-width: 30px !important;
}

.table.table-dark tbody tr td {
  padding: 4px;
}

.table.table-dark tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.table.table-dark th {
  border-right: 1px solid #dee2e6;
}


.table.table-dark td {
  border-right: 1px solid #dee2e6;
}


.table.table-dark td:first-child,
.table.table-dark th:first-child {
  border-left: none;
}


.table.table-dark td:last-child,
.table.table-dark th:last-child {
  border-right: none;
}


@media (max-width: 767px) {
  table {
    border-collapse: collapse !important;
  }

  th, td {
    background-color: #293a40 !important;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;

  }

  .hidden-column {
    display: none;
  }

  .mobileShow {
    display: table-cell;
  }

  .expandable-content {
    background-color: #293a40 !important;
    display: none !important;
  }

  .expandable-content.visible {
    display: table-row !important;
  }

  .expandable-content td {
    border-top: 1px solid #ddd;
    display: table-cell !important;
  }


  .expandable-content th,
  .expandable-content td {
    background-color: #343A40;
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
    display: table-cell !important;
  }
}


.pagination-button {
  background-color: #343a40;
  color: #ffffff;
  border: 1px solid #606c76;
  cursor: pointer;
  padding: 8px 12px;
  margin: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}


.pagination-button:hover {
  background-color: #495057;
  color: #ffffff;
}


.pagination-button.active {
  background-color: #007bff;
  color: #ffffff;
}


.pagination-button.disabled {
  background-color: #6c757d;
  color: #ffffff;
  cursor: not-allowed;
}

.clear-button {
  background-color: #293a40;
  color: #ffffff;
  border: 1px solid #dee2e6;
  width: 100%;
  height: 35px;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  display: block;
}


.clear-button:hover {
  background-color: #1c2930;
}

.button-text {
  color: #DEE2E6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.loader-container {
  position: fixed;
  top: 35%;
  left: 45%;
  transform: translate(-40%, -45%);
  backdrop-filter: blur(5px);
  background: rgba(33, 37, 41, 0.8);
  border-radius: 10px;
  padding: 100px;
}

.loader {
  color: #fff;
  position: fixed;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
}

</style>
