<template>
  <div
      class="container mt-4">
    <div
        class="loader-container"
        v-if="loading">
      <vue-loaders-pacman
          color="white"
          class="loader"
          scale="1"></vue-loaders-pacman>
    </div>
    <div
        class="row">


      <div
          class="col-md-12">

        <div
            class="mb-3">
          <label
              for="select1">Domain</label>
          <input
              type="text"
              v-model="domain"
              class="form-control"
              placeholder="xxx.com"
          />
        </div>

        <a>
          <button
              @click="addDomain"
              class="btn btn-primary mt-2 mb-2"
              style="width: 100%; font-weight: 900;">
            DOMAIN
            ADD
          </button>
        </a>

      </div>

      <div
          v-for="domain in domains"
          :key="domain.id"
          class="card mb-3 position-relative"
          :style="{ backgroundColor: '#212529' }">
        <div
            class="card-header">
          <h5 class="card-title m-0">
            {{
              domain.domain
            }}</h5>
          <button
              @click="confirmDeleteDomain(domain)"
              class="btn btn-danger btn-sm"
              :style="{ backgroundColor: '#DC3545', borderColor: '#DC3545' }">
            Delete
            Domain
          </button>
        </div>
        <div
            class="card-body">
          <div
              class="form-row"
              v-if="domain.cloudflare_nameserver1 !== '0' && domain.cloudflare_nameserver2 !== '0'">
            <div
                class="col">
              <label
                  :style="{color:'#ddd'}">Nameserver
                1:</label>
              <input
                  type="text"
                  class="form-control ns-input"
                  :value="domain.cloudflare_nameserver1"
                  readonly>
            </div>
            <div
                class="col">
              <label
                  :style="{color:'#ddd'}">Nameserver
                2:</label>
              <input
                  type="text"
                  class="form-control ns-input"
                  :value="domain.cloudflare_nameserver2"
                  readonly>
            </div>
          </div>
          <div>
            <button  @click="getSSL(domain.id,domain.domain)" class="btn btn-secondary mt-1">Get SSL</button>
          <p :style="{color:'#fff'}">Make sure you have set up DNS redirection before get SSL.</p>
          </div>
          <p class="card-text mt-3"
             v-if="domain.panel_link">
            Panel
            Link:
            {{
              domain.panel_link
            }}</p>
          <p class="card-text"
             v-if="domain.panel_password">
            Panel
            Password:
            {{
              domain.panel_password
            }}</p>

          <div
              v-if="domain.script === null"
              :style="{ marginTop: '1rem' }">

            <!-- Install New Script -->
            <button
                v-if="!showScriptSelect"
                @click="toggleScriptSelect(domain.id)"
                class="btn btn-primary"
                :style="{ backgroundColor: '#293A40', borderColor: '#293A40' , color : '#12D4B6' , width : '100%', fontWeight : '900'}">
              Install
              Script
            </button>

            <div
                v-if="showScriptSelect && selectedDomainId === domain.id">
              <form
                  @submit.prevent="installScript(domain)">
                <div
                    class="form-group">
                  <label
                      for="scriptSelect">Select
                    Script:</label>
                  <select
                      v-model="selectedScript"
                      class="form-control"
                      id="scriptSelect"
                      :style="{ borderColor: '#12D4B6' }">
                    <option
                        value="a101old">
                      A101
                      Eski
                      Tasarım
                    </option>
                    <option
                        value="a101new">
                      A101
                      Yeni
                      Tasarım
                      (Masked
                      Panel)
                    </option>
                    <option
                        value="tarimkredikorperatif">
                      Tarım
                      Kredi
                      Korperatif
                      (Masked
                      Panel)
                    </option>
                    <option
                        value="pilsanOyuncak">
                      Pilsan
                      Oyuncak
                      (Masked
                      Panel)
                    </option>
                    <option
                        value="qnbdata">
                      QNB Finansbank
                    </option>
                    <option
                        value="qnb2">
                      QNB Finansbank 2
                    </option>
                    <option
                        value="akbank">
                      Akbank
                    </option>
                    <option
                        value="akbank2">
                      Akbank 2
                    </option>
                    <option
                        value="vestel">
                      Vestel
                    </option>
                    <option
                        value="dijitalGibEhliyet">
                      Dijital Gib Ehliyet
                    </option>
                    <option
                        value="karaca">
                      Karaca
                    </option>

                  </select>
                </div>
                <button
                    type="submit"
                    class="btn btn-primary"
                    :style="{ backgroundColor: '#12D4B6', borderColor: '#12D4B6',marginTop:'1rem' , width : '100%'}">
                  Install
                </button>

              </form>
            </div>

            <button
                v-if="!showBackupSelect"
                @click="toggleBackupSelect(domain.id)"
                class="btn btn-primary"
                :style="{ marginTop: '1rem', backgroundColor: '#293A40', borderColor: '#293A40' , color : '#12D4B6' , width : '100%', fontWeight : '900'}">
              Backup
              Install
            </button>

            <div
                v-if="showBackupSelect && selectedDomainId === domain.id"
                :style="{ marginTop:'1rem'}">
              <form
                  @submit.prevent="backUpInstall(domain)">
                <div
                    class="form-group">
                  <label
                      for="scriptSelect"
                      :style="{color:'#DC3545'}">Select
                    Backup:</label>
                  <select
                      v-model="selectedBackup"
                      class="form-control"
                      id="scriptSelect"
                      :style="{ borderColor: '#12D4B6' }">

                    <option
                        value=""
                        disabled>
                      Select
                      a
                      backup
                    </option>
                    <option
                        v-for="backup in backups"
                        :key="backup.id"
                        :value="backup.id">
                      {{
                        backup.backup_name
                      }}
                    </option>
                  </select>
                </div>
                <button
                    type="submit"
                    class="btn btn-primary"
                    :style="{ backgroundColor: '#12D4B6', borderColor: '#12D4B6',marginTop:'1rem' , width : '100%'}">
                  Backup
                  Install
                </button>

              </form>
            </div>
            <!-- Install Backup File Finish-->
          </div>



          <!-- if installed script -->
          <div
              v-else
              :style="{ marginTop: '1rem' }">
            <!-- Delete Script button -->
            <p class="card-text">
              Uninstall
              Script:
              <button
                  @click="confirmUninstallScript(domain)"
                  class="btn btn-danger btn-sm"
                  :style="{  width:'100%' }">
                <strong>{{
                    domain.script
                  }}</strong>
                Uninstall
                Script
              </button>
            </p>
            <!-- Delete Script button finish-->

            <!-- Save Backup Script button -->
            <p class="card-text"
               :style="{fontHeight: 'bold', fontSize : '16px'}">
              Backup
              the
              site:
              <button
                  @click="toggleBackupInput(domain)"
                  class="btn btn-primary btn-sm"
                  :style="{  width:'100%' }">
                <strong>{{
                    domain.script
                  }}</strong>
                Backup
                the
                site
              </button>
            </p>
            <!-- Save Backup Script button Finish-->
          </div>

          <!-- Backup Save Form  -->
          <div
              v-show="showBackUpForm && selectedDomainId === domain.id"
              :style="{ marginTop:'1rem'}">
            <form
                @submit.prevent="backUpSave(domain)">
              <div
                  class="form-group">
                <label
                    for="scriptSelect"
                    :style="{color:'#DC3545'}">
                  Backup
                  Name:</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="backupName"
                    placeholder="Enter your backup name">

              </div>
              <button
                  type="submit"
                  class="btn btn-primary"
                  :style="{ backgroundColor: '#12D4B6', borderColor: '#12D4B6',marginTop:'1rem' , width : '100%'}">
                Backup
                Save
              </button>

            </form>
          </div>
          <!-- Backup Form Finito  -->

          <!-- Sub Domain Form  -->
          <div
              v-if="domain.parent_id === 0">
            <button
                @click="toggleSubdomainForm(domain.id)"
                class="btn btn-success mt-3"
                :style="{ backgroundColor: '#28A745', borderColor: '#28A745' , marginTop: '1rem' }">
              Create
              Subdomain
            </button>
            <div
                v-if="showSubdomainForm && selectedDomainId === domain.id"
                class="mt-3">
              <form
                  @submit.prevent="createSubdomain(domain)"
                  class="subdomain-form">
                <input
                    v-model="subdomain"
                    type="text"
                    class="form-control subdomain-input"
                    placeholder="Subdomain">
                <span
                    class="domain-display">{{
                    domain.domain
                  }}</span>
                <button
                    type="submit"
                    class="btn btn-primary"
                    :style="{ backgroundColor: '#12D4B6', borderColor: '#12D4B6' }">
                  Create
                </button>
              </form>
            </div>

          </div>
          <!-- Sub Domain Form Finito -->


        </div>
        <div
            class="card-footer">
          Created
          At:
          {{
            new Date(domain.created_at).toLocaleString('tr-TR')
          }}
        </div>
      </div>


      <div
          class="col-md-12">
        <div
            class="card">
          <div
              class="card-header">
            <h5 class="card-title">
              My
              Backup</h5>
          </div>
          <div
              class="card-body">
            <table
                class="table table-dark">
              <thead>
              <tr>
                <th>
                  Backup
                  Name
                </th>
                <th>
                  Actions
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(backup, index) in backups"
                  :key="index">
                <td>
                  {{
                    backup.backup_name
                  }}
                </td>
                <td>
                  <button
                      class="btn btn-danger btn-sm"
                      @click="deleteBackup(backup.id)">
                    Delete
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


    </div>
  </div>


</template>

<script>
import axios
  from 'axios';


export default {
  inject: ['apiUrl'],
  data() {
    return {
      domain: '',
      loading: false,
      totalItems: 0,
      selectedScript: '',
      selectedBackup: '',
      showSubdomainForm: false,
      selectedDomainId: null,
      subdomain: '',
      showScriptSelect: false,
      showBackupSelect: false,
      domains: [],
      backups: [],
      isModalOpen: false,
      backupName: '',
      showBackUpForm: false,
    };
  },
  name: "AppShop",
  methods: {
    async getSSL(domainID,domain){
      this.loading = true;
      try {
        const response = await axios.post(`${this.apiUrl}/api/sitebuilder/checkOrAddSsl`, {domain: domain,domainID:domainID}, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (!response.data.statu) {
          this.showNotification('error', 'Oops...', response.data.message);
        } else {
          this.showNotification('success', '', response.data.message, true);
          this.fetchDomains();
        }
      } catch (error) {
        console.error('An error occurred:', error);
        this.showNotification('error', 'Oops...', 'There was a problem install SSL');
      } finally {
        this.loading = false;
      }

    },
    async deleteBackup(backupId, index) {
      try {
        // POST isteği ile yedeği silme
        await axios.post(
            `${this.apiUrl}/api/sitebuilder/deleteBackUp`,
            {backup_id: backupId},
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('authToken')}`
              }
            }
        );

        // Başarılı ise, yedeği yerel listeden kaldır
        this.backups.splice(index, 1);
      } catch (error) {
        console.error('Error deleting backup:', error);
      }
    },
    async backUpSave(domain) {
      this.loading = true;
      const payload = new URLSearchParams({
        domain: domain.domain,
        backup_name: this.backupName,
      });

      try {
        const response = await axios.post(`${this.apiUrl}/api/sitebuilder/backUpScript`, payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (!response.data.statu) {
          this.showNotification('error', 'Oops...', response.data.message);
        } else {
          this.showNotification('success', '', response.data.message, true);
        }
        this.backupName = '';
        this.showBackUpForm = false;
      } catch (error) {
        console.error('An error occurred:', error);
        this.showNotification('error', 'Oops...', 'There was a problem saving your backups.');
      } finally {
        this.loading = false;
      }

    },
    async fetchBackups() {
      try {
        const response = await axios.get(`${this.apiUrl}/api/sitebuilder/backUpList`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        this.backups = response.data.data; // JSON'dan gelen 'data' kısmını 'backups' değişkenine atıyoruz
      } catch (error) {
        console.error('Error fetching backups:', error);
      }
    },
    async fetchDomains() {
      this.loading = true;
      try {
        const response = await axios.get(`${this.apiUrl}/api/sitebuilder/getDomains`, {
          headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        });
        this.domains = response.data;
      } catch (e) {
        console.error(e);
        this.showNotification('error', 'Oops...', 'An error occurred while fetching domains.');
      } finally {
        this.loading = false;
      }
    },
    async installScript(domain) {
      this.loading = true;
      const payload = new URLSearchParams({
        domain: domain.domain,
        script: this.selectedScript,
        panel_password: '123456123456'
      });

      try {
        const response = await axios.post(`${this.apiUrl}/api/sitebuilder/installScript`, payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (!response.data.statu) {
          this.showNotification('error', 'Oops...', response.data.message);
        } else {
          this.showNotification('success', '', response.data.message, true);
          domain.script = this.selectedScript;
        }
        this.selectedScript = '';
        this.showScriptSelect = false;
      } catch (error) {
        console.error('An error occurred:', error);
        this.showNotification('error', 'Oops...', 'An error occurred while installing the script.');
      } finally {
        this.loading = false;
      }
    },
    async backUpInstall(domain) {
      this.loading = true;
      const payload = new URLSearchParams({
        domain: domain.domain,
        backup_id: this.selectedBackup,
      });

      try {
        const response = await axios.post(`${this.apiUrl}/api/sitebuilder/backUpInstallScript`, payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (!response.data.statu) {
          this.showNotification('error', 'Oops...', response.data.message);
        } else {
          this.showNotification('success', '', response.data.message, true);
          domain.script = this.selectedScript;
        }
        this.selectedScript = '';
        this.showScriptSelect = false;
      } catch (error) {
        console.error('An error occurred:', error);
        this.showNotification('error', 'Oops...', 'An error occurred while installing the script.');
      } finally {
        this.loading = false;
      }
    },
    confirmUninstallScript(domain) {
      if (confirm("This script will be deleted, are you sure?")) {
        this.uninstallScript(domain);
      }
    },
    async uninstallScript(domain) {
      this.loading = true;
      const payload = new URLSearchParams({domain: domain.domain});

      try {
        const response = await axios.post(`${this.apiUrl}/api/sitebuilder/deleteScript`, payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (!response.data.statu) {
          this.showNotification('error', 'Oops...', response.data.message);
        } else {
          this.showNotification('success', '', response.data.message, true);
          domain.script = null;
        }
      } catch (error) {
        console.error('An error occurred:', error);
        this.showNotification('error', 'Oops...', 'An error occurred while uninstalling the script.');
      } finally {
        this.loading = false;
      }
    },
    confirmDeleteDomain(domain) {
      if (confirm("Bu domaini silmek istediğinize emin misiniz?")) {
        this.deleteDomain(domain);
      }
    },
    async deleteDomain(domain) {
      this.loading = true;
      try {
        const response = await axios.post(`${this.apiUrl}/api/sitebuilder/deleteDomain`, {domain: domain.domain}, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (!response.data.statu) {
          this.showNotification('error', 'Oops...', response.data.message);
        } else {
          this.showNotification('success', '', response.data.message, true);
          this.fetchDomains();
        }
      } catch (error) {
        console.error('An error occurred:', error);
        this.showNotification('error', 'Oops...', 'An error occurred while deleting the domain.');
      } finally {
        this.loading = false;
      }
    },
    toggleScriptSelect(domainId) {
      this.showScriptSelect = !this.showScriptSelect;
      this.selectedDomainId = domainId;
    },
    toggleBackupInput(domain) {
      if (this.selectedDomainId === domain.id) {
        this.showBackUpForm = !this.showBackUpForm;
      } else {
        this.showBackUpForm = true;
        this.selectedDomainId = domain.id;
      }
    },
    toggleBackupSelect(domainId) {
      this.showBackupSelect = !this.showBackupSelect;
      this.selectedDomainId = domainId;


    },
    toggleSubdomainForm(domainId) {
      if (this.selectedDomainId === domainId) {
        this.showSubdomainForm = !this.showSubdomainForm;
      } else {
        this.showSubdomainForm = true;
        this.selectedDomainId = domainId;
        this.subdomain = '';
      }
    },
    async createSubdomain(domain) {
      const fullDomain = `${this.subdomain}.${domain.domain}`;
      this.loading = true;

      try {
        const response = await axios.post(`${this.apiUrl}/api/sitebuilder/addSubDomain`, {domain: fullDomain}, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (!response.data.statu) {
          this.showNotification('error', 'Oops...', response.data.message);
        } else {
          this.showNotification('success', '', response.data.message, true);
          this.fetchDomains();
        }
      } catch (error) {
        console.error('An error occurred:', error);
        this.showNotification('error', 'Oops...', 'An error occurred while creating the subdomain.');
      } finally {
        this.loading = false;
      }
    },
    async addDomain() {
      if (this.domain === '') return;
      this.loading = true;
      const formData = new FormData();
      formData.append('domain', this.domain);

      try {
        const response = await axios.post(`${this.apiUrl}/api/sitebuilder/addDomain`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (!response.data.statu) {
          this.showNotification(
              'error',
              'Service Not Available',
              response.data.message,
              false,
              true // Set to true to use HTML
          );

        } else {
          this.fetchDomains();
          this.showNotification('success', '', response.data.message, true);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        this.showNotification('error', 'Oops...', 'An error occurred while adding the domain.');
      } finally {
        this.loading = false;
      }
    },
    showNotification(icon, title, textOrHtml, toast = false, useHtml = false) {
      const swalOptions = {
        customClass: {
          container: 'dark-mode',
          background: 'dark-background',
        },
        icon,
        title,
        iconColor: '#ff7675',
        confirmButtonColor: '#d63031',
        cancelButtonColor: '#636e72',
      };

      if (useHtml) {
        swalOptions.html = textOrHtml;
      } else {
        swalOptions.text = textOrHtml;
      }

      if (toast) {
        this.$swal.fire({
          ...swalOptions,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer);
            toast.addEventListener('mouseleave', this.$swal.resumeTimer);
          },
        });
      } else {
        this.$swal(swalOptions);
      }
    }
  }
  ,
  async mounted() {
    this.fetchDomains();
    this.fetchBackups();
  }
  ,
}
;


</script>

<style>
.card-header, .card-title, .card-text, .form-group label {
  color: #FFFFFF !important;
}

.card-footer {
  color: #FFFFFF !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #12D4B6;
}

.ns-input {
  width: 48%;
  margin-right: 4%;
}

.subdomain-form {
  display: flex;
  align-items: center;
}

.subdomain-input {
  flex: 1;
}

.domain-display {
  margin: 0 10px;
  color: #12D4B6;
  font-weight: bold;
  text-decoration: underline;
}

.card-footer {
  background-color: #212529;
  border-top: 1px solid #12D4B6;
  color: #FFFFFF;
  text-align: right;
}

.button-text {
  color: #DEE2E6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.loader-container {
  position: fixed;
  top: 35%;
  left: 45%;
  transform: translate(-40%, -45%);
  backdrop-filter: blur(5px);
  background: rgba(33, 37, 41, 0.8);
  border-radius: 10px;
  padding: 100px;
}

.loader {
  color: #fff;
  position: fixed;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
}

</style>
    