<!-- src/views/Home.vue -->
<template>
<div></div>
  </template>
  
  <script>
  export default {
    name: 'AppSidebar',
  };
  </script>
  
  <style scoped>

  </style>
  