<template>
  <div class="container mt-2">
    <div class="loader-container" v-if="loading">
      <vue-loaders-pacman color="white" class="loader" scale="1"></vue-loaders-pacman>
    </div>

    <div class="row">

      <div class="col-md-3 mt-3">
        <div class="color-box bg-primary position-relative">
          <span class="icon"><font-awesome-icon :icon="['fas', 'user']" class="custom-style"/></span>
          <h5 class="text-start mb-0 info-text">Total Users</h5>
          <p class="text-start"
             style="font-size: 18px; font-weight: bold; color: #fff; background-color: #3498db; padding: 5px 5px !important; border-radius: 1px;">
            {{ memberCount }}</p>

        </div>
      </div>

      <div class="col-md-3 mt-3 mt-3">
        <div class="color-box bg-warning position-relative">
          <span class="icon"><font-awesome-icon
              style="filter: drop-shadow(2px 4px 6px rgba(255,249,249,0.3)); " icon="credit-card"/></span>
          <h5 class="text-start mb-0 info-text">Total Check </h5>
          <p class="text-start"
             style="font-size: 18px; font-weight: bold; color: #fff; background-color: #f39c12; padding: 5px 5px !important; border-radius: 1px;">
            {{ totalCheck }}</p>
        </div>
      </div>

      <div class="col-md-3 mt-3">
        <div class="color-box bg-success position-relative">
          <span class="icon"><font-awesome-icon
              style="filter: drop-shadow(2px 4px 6px rgba(255,249,249,0.3)); " icon="check-double"/></span>
          <h5 class="text-start mb-0 info-text">Total Live
            <font-awesome-icon
                style="" icon="heart"/>
          </h5>
          <p class="text-start"
             style="font-size: 18px; font-weight: bold; color: #fff; background-color: #2ecc71; padding: 5px 5px !important; border-radius: 1px;">
            {{ totalLive }}</p>
        </div>
      </div>


      <div class="col-md-3 mt-3">
        <div class="color-box bg-danger position-relative">
          <span class="icon"><font-awesome-icon
              style="filter: drop-shadow(2px 4px 6px rgba(255,249,249,0.3)); " icon="trash"/></span>
          <h5 class="text-start mb-0 info-text">Decliened</h5>
          <p class="text-start"
             style="font-size: 18px; font-weight: bold; color: #fff; background-color: #e74c3c; padding: 5px 5px !important; border-radius: 1px;">
            {{ decliened }}</p>
        </div>
      </div>


    </div>

    <div class="col-md-12">
      <h3 class="mt-4">Announcements</h3>
      <div class="contentCard ">
        <div class="card mt-3" v-for="(announcement, index) in announcements" :key="index"
             :style="'background: linear-gradient(to right, '+this.getCardColor(announcement.property)+' 0, '+this.getCardColor(announcement.property)+' 55px, #212529 55px, #212529 100%) !important;'">
          <!-- Card Header -->
          <div class="card-header"
               :style="'background: linear-gradient(to right, '+this.getCardColor(announcement.property)+' 0, '+this.getCardColor(announcement.property)+' 55px, #212529 55px, #212529 100%) !important;   border-bottom: 2px solid '+this.getCardColor(announcement.property)+';'">
            <!-- Sol Icon ve Başlık -->
            <div class="left-content">
              <div class="">
                <font-awesome-icon :icon="announcement.icon" class="announcementsIcon"/>
              </div>
              <div>
                <div style="margin-left: 3rem" v-html="announcement.title"></div>
              </div>
            </div>

            <div class="float-end">
              <div style="color: #726c6c; !important;">
                <font-awesome-icon style="" icon="calendar-days"/>
                {{ this.formatDate(announcement.created_at) }}
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="infoContent" style="margin-left: 45px" v-html="announcement.content "></div>
          </div>
        </div>
      </div>
    </div>

  </div>


</template>


<script>

import axios from "axios";
import {AuthMixin} from '@/mixins/AuthMixin';

export default {
  mixins: [AuthMixin],
  inject: ['apiUrl'],
  name: "AppHome",
  data() {
    return {
      memberCount: null,
      totalCheck: null,
      totalLive: null,
      decliened: null,
      loading: false,
      announcements: []
    };
  },
  async created() {
    try {
      this.loading = true;
      const response = await axios.get(this.apiUrl + '/api/generalInfo', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (response.data.error) {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: response.data.error,
          customClass: {
            container: 'dark-mode',
            background: 'dark-background',
          },
          iconColor: '#ff7675',
          confirmButtonColor: '#d63031',
          cancelButtonColor: '#636e72',
        });

      } else {
        this.memberCount = response.data.totalUsers;
        this.totalCheck = response.data.totalCheck;
        this.totalLive = response.data.live;
        this.decliened = response.data.decliened;

        const responseNofiy = await axios.get(this.apiUrl + '/api/get-notifications', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        this.announcements = responseNofiy.data.nofication;

      }
      this.loading = false;
    } catch (error) {
      console.error('Error status login status');
    }
  },
  methods: {
    getCardColor(property) {
      switch (property) {
        case 'primary':
          return '#3498db';
        case 'warning':
          return '#f39c12';
        case 'danger':
          return '#e74c3c';
        case 'success':
          return '#2ecc71';
        default:
          return 'dark';
      }
    },
    formatDate(dateString) {
      const options = {year: 'numeric', month: 'long', day: 'numeric'};
      return new Date(dateString).toLocaleDateString('en-US', options);
    }
  }
};
</script>

<style>


.color-box {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

}

.info-text {
  font-weight: 900 !important;
}

.color-box:hover {
  transform: scale(1.05);
}

.color-box {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  background-color: #343A40;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.icon {
  font-size: 6em;
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
}

.bg-warning .icon {
  color: #f39c12;
}


.bg-primary .icon {
  color: #3498db;
}


.bg-success .icon {
  color: #2ecc71;
}


.bg-danger .icon {
  color: #e84e3c;
}


.text-start {
  margin-left: 10px;
}


.infoContent ul {
  list-style-type: none;
  padding: 0;
}

.infoContent li {
  list-style-type: none;
}

.infoContent li::before {
  content: '';
  color: #666;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}


.contentCard .card {

  border: none;
  overflow: hidden;
}

.contentCard .card-header {
  display: flex;
  justify-content: space-between;
  position: relative;
}


.contentCard .card-header .left-content {
  display: flex;
  align-items: center;
  position: relative;
}

.contentCard .card {

  position: relative;
  overflow: hidden;
}


.contentCard .card::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50px;
  width: 1px;
  height: 100%;

  z-index: 1;
}

.card-header .announcementsIcon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #e5e3e3 !important;
  font-size: 25px;
  z-index: 10;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}


.card-header h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-left: 45px;
  position: relative;
  z-index: 5;
}


.card-header .float-end {
  position: relative;
}

.contentCard .card-body {
  color: #FCF8F8 !important;
}


.color-box {
  padding: 20px;
  text-align: center;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
}


.loader-container {
  position: fixed;
  top: 35%;
  left: 45%;
  transform: translate(-40%, -45%);
  backdrop-filter: blur(5px);
  background: rgba(33, 37, 41, 0.8);
  border-radius: 10px;
  padding: 100px;
  z-index: 400;
}

.loader {
  color: #fff;
  position: fixed;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
}
</style>