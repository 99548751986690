<template>
  <div class="background"></div>
  <div class="glitch-container">
    <div class="glitch"></div>

  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6" style="margin-top: 3rem">
        <div v-if="!loggedIn" id="loginCard" class="card">
          <div class="card-body">
            <h1 class="card-title text-center">Login</h1>
            <form @submit.prevent="login">
              <div class="mb-3">
                <label for="username" class="form-label">Username</label>
                <input v-model="username" type="text" class="form-control" id="username" required/>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input v-model="password" type="password" class="form-control" id="password" required/>
              </div>
              <button type="submit" class="btn btn-primary" style="width: 100%;">LOGIN</button>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>


<script>

import {AuthMixin} from '@/mixins/AuthMixin';

export default {
  mixins: [AuthMixin],
  data() {
    return {

    };
  },
  methods: {},
};
</script>


<style >
.background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url('http://localhost:8000/img/bg1.jpg');
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
}

.glitch-container {

  position: relative;
  overflow: hidden;
  width: 100%; /* Ekledim */
  height: 100vh; /* Ekledim */
}

.glitch {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('http://localhost:8000/img/bg-overlay.png');
  background-size: cover;
  mix-blend-mode: overlay; /* Ekledim */
  animation: glitchAnimation 1s infinite alternate;
}
#loginCard {
  background-color: #212529 !important;
  color: #fff;
}


@keyframes glitch {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 5px 5px;
  }
  50% {
    background-position: -5px -5px;
  }
  75% {
    background-position: 5px -5px;
  }
  100% {
    background-position: -5px 5px;
  }
}

</style>