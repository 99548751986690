<!-- src/App.vue -->
<template>

  <AppHeader/>
  <router-view/>
  <AppSidebar/>
  <AppFooter/>


</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppSidebar from './components/AppSidebar.vue';
import AppFooter from './components/AppFooter.vue';
import {AuthMixin} from '@/mixins/AuthMixin';


export default {
  inject: ['apiUrl'],
  mixins: [AuthMixin],
  data() {
    return {
      loggedIn: false,
      user: null,
      username: '',
      balance: '',
      password: ''
    }
  },
  watch: {
    '$route'(to) {
      document.title = to.meta.title || 'Solo CC Shop';
    },
    loggedIn(newValue) {
      console.log('loggedIn değişti:', newValue);
    }
  },
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
  },
};
</script>

<style>
body {
  background-color: #343A40 !important;
  color: white !important;
}

input, textarea, select {
  background-color: #293A40 !important;
  color: white !important;
}

.form-control::placeholder {
  color: rgb(175, 174, 174) !important;
}

.dark-background {
  background-color: #2d3436 !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #12d4b6 !important;
  --bs-btn-border-color: #04ae94 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #04c3a5 !important;
  --bs-btn-hover-border-color: #04ae94 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #04ae94 !important;
  --bs-btn-active-border-color: #04ae94 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #04ae94 !important;
  --bs-btn-disabled-border-color: #026152 !important;
}

.navbar-dark {
  --bs-navbar-color: #12D4B6 !important;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath fill='%237EF2E7' stroke='%2312D4B6' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") center/100% no-repeat !important;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  background-color: #f8f8f8;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #2c3e50;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  color: #12d4b6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section {
  padding: 80px 0;
}

.header {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.navbar-brand {
  font-size: 1.8em;
  font-weight: bold;
  color: #2c3e50;
}

.navbar-nav {
  display: flex;
  list-style: none;
}

.nav-item {
  margin-right: 20px;
}

.nav-link {
  color: #726c6c !important;
  font-weight: bold;
  text-decoration: none;
}

.nav-link:hover {
  color: #12d4b6 !important;
}

.form-control:focus {
  border-color: #12d4b6;
  box-shadow: 0 0 0 0.25rem rgba(18, 212, 182, 0.25) !important;
}

.bold-text {
  font: bold 16px/1.5 sans-serif;
}

.bg-primary {
  background-color: #3498db !important
}

.bg-success {
  background-color: #2ecc71 !important;
}

.bg-warning {
  background-color: #f39c12 !important;
}


.bg-danger {
  background-color: #e74c3c !important;
}

.dark-mode {
  background-color: #343a40 !important;
  color: #fff !important;
}

.dark-mode-item {
  background-color: #2c3036 !important;
  border-color: #545b62 !important;
  color: #fff !important;
}

.dark-modeMer {
  background-color: #212529;
  color: #fff;
}

.test {

}

</style>

<head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<link rel="stylesheet" href="https://necolas.github.io/normalize.css/latest/normalize.css">
</head>

<body>

</body>