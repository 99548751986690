<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 mb-4">
        <div class="card bg-dark">
          <div class="card-header">
            <h4 class="card-title text-white">Password Change</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="passwordChange">
              <div class="form-group">
                <label for="currentPassword">Password</label>
                <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    id="currentPassword"
                    placeholder="Password"
                >
              </div>
              <div class="form-group">
                <label for="newPassword">Yeni Şifre</label>
                <input
                    v-model="newPassword"
                    type="password"
                    class="form-control"
                    id="newPassword"
                    placeholder="New Password"
                >
              </div>
              <div class="form-group">
                <label for="confirmPassword">Yeni Şifre (Tekrar)</label>
                <input
                    v-model="confirmPassword"
                    type="password"
                    class="form-control"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                >
              </div>
              <button style="width: 100%;" type="submit" class="btn btn-primary mt-4">
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-4">
        <div class="card bg-dark">
          <div class="card-header">
            <h4 class="card-title text-white">Telegram User</h4>
          </div>
          <div class="card-body">
            <form>
              <div class="form-group">
                <label for="tguserName">Username:</label>
                <input
                    type="text"
                    class="form-control"
                    id="tguserName"
                    placeholder="User Name"
                    disabled
                >
              </div>
              <button style="width: 100%;" type="submit" class="btn btn-primary mt-4">
                Change Telegram
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>


<script>
import {
  AuthMixin
} from '@/mixins/AuthMixin';
import axios
  from "axios";


export default {
  mixins: [AuthMixin],
  name: "AppProfile",
  data() {
    return {
      password: '',
      newPassword: '',
      confirmPassword: '',
      telegramName: ''
    };
  },
  inject: ['apiUrl'],
  methods: {
    async passwordChange() {
      try {
        if (this.password !== '' && this.newPassword !== '' && this.confirmPassword !== '') {
          const formData = new FormData();
          formData.append('password', this.password);
          formData.append('newPassword', this.newPassword);
          formData.append('confirmPassword', this.confirmPassword);

          const response = await axios.post(
              this.apiUrl + '/api/passwordReset',
              formData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('authToken')}`
                },
              }
          );

          if (response.data.status === false) {
            this.$swal({
              icon: 'error',
              title: 'Oops...',
              text: response.data.message,
              customClass: {
                container: 'dark-mode',
                background: 'dark-background',
              },
              iconColor: '#ff7675',
              confirmButtonColor: '#d63031',
              cancelButtonColor: '#636e72',
            });
          } else {
            this.$swal.fire({
              icon: 'success',
              title: response.data.message,
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              customClass: {
                popup: 'dark-mode',
                header: 'dark-mode',
                title: 'dark-mode',
                closeButton: 'dark-mode',
                icon: 'dark-mode',
                image: 'dark-mode',
                content: 'dark-mode',
                input: 'dark-mode',
                actions: 'dark-mode',
                confirmButton: 'dark-mode',
                cancelButton: 'dark-mode',
                footer: 'dark-mode'
              },
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', this.$swal.stopTimer);
                toast.addEventListener('mouseleave', this.$swal.resumeTimer);
              },
            });
          }
        }
      } catch (error) {
        console.error(error);
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          customClass: {
            container: 'dark-mode',
            background: 'dark-background',
          },
          iconColor: '#ff7675',
          confirmButtonColor: '#d63031',
          cancelButtonColor: '#636e72',
        });
      }
    }

  },
  async created() {
  },

};
</script>

<style
    scoped>
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

label {
  color: #fff;
}

.profile-sidebar {
  display: flex;
  background-color: #343a40;
  color: #fff;
  flex-direction: row;
}

.nav-item {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-item:hover {
  background-color: #212529;
  transform: scale(1.2);
}

.nav-text {
  font-size: 12px;
}

.main-content {

}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card {
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-header {
  background-color: #343a40;
  color: #fff;
  padding: 15px;
  text-align: center;
}

.card-body {
  padding: 20px;
}

.card-footer {
  background-color: #343a40;
  padding: 15px;
  text-align: center;
}

.card-footer button {
  background-color: #17a2b8;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-footer button:hover {
  background-color: #138496;
}

.loader-container {
  position: fixed;
  top: 35%;
  left: 45%;
  transform: translate(-40%, -45%);
  backdrop-filter: blur(5px);
  background: rgba(33, 37, 41, 0.8);
  border-radius: 10px;
  padding: 100px;
  z-index: 400;
}

.loader {
  color: #fff;
  position: fixed;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
}
</style>